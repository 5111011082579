import gql from 'graphql-tag'

export const SIGNUP_MUTATION = gql`
  mutation ($input: SignUpInput!) {
    signUp(input: $input) {
      user {
        id
        firstName
        lastName
        email
      }
      token
      errors {
        path
        detail
      }
    }
  }
`

export const SIGNIN_MUTATION = gql`
  mutation ($input: SignInInput!) {
    signIn(input: $input) {
      user {
        id
        firstName
        lastName
        email
      }
      token
      errors {
        path
        detail
      }
    }
  }
`

export const SIGNIN_WITH_CODE_MUTATION = gql`
  mutation ($code: String!, $referral: String) {
    signInWithCode(code: $code, referral: $referral) {
      user {
        id
        firstName
        lastName
        email
      }
      token
      isNew
      errors {
        path
        detail
      }
    }
  }
`

export const SEND_RESET_PASSWORD_EMAIL_MUTATION = gql`
  mutation ($email: String!) {
    sendResetPasswordEmail(email: $email) {
      success
      errors {
        detail
      }
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      token
      user {
        id
        firstName
        lastName
        email
      }
      success
      errors {
        detail
      }
    }
  }
`

export const UPDATE_INFO_MUTATION = gql`
  mutation ($firstName: String!, $lastName: String!) {
    updateInfo(firstName: $firstName, lastName: $lastName) {
      user {
        id
        firstName
        lastName
        email
      }
      token
      errors {
        path
        detail
      }
    }
  }
`

export const CREATE_PROJECT_MUTATION = gql`
  mutation ($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      projectType
      userId
      title
      deadline
      recipientName
      recipientEmail
      introVideoUrl
      numCompleted
      numInvited
      shareUrl
      user {
        id
        firstName
      }
      asks {
        id
        text
        order
        createdAt
      }
    }
  }
`

export const CREATE_OR_GET_UPCOMING_BIRTHDAY_PROJECT_MUTATION = gql`
  mutation ($contactSlug: String!) {
    createOrGetUpcomingBirthdayProject(contactSlug: $contactSlug) {
      project {
        id
        projectType
        userId
        title
        deadline
        recipientName
        recipientEmail
        introVideoUrl
        numCompleted
        numInvited
        shareUrl
        user {
          id
          firstName
        }
        asks {
          id
          text
          order
          createdAt
        }
      }
      errors {
        detail
      }
    }
  }
`

export const DELETE_PROJECT_MUTATION = gql`
  mutation ($projectId: ID!) {
    deleteProject(projectId: $projectId) {
      success
      errors {
        detail
      }
    }
  }
`

export const UPDATE_PROJECT_MUTATION = gql`
  mutation ($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      projectType
      userId
      title
      deadline
      recipientName
      recipientEmail
      introVideoUrl
      numCompleted
      numInvited
      soundtrackUrl
      shareUrl
      showAsk
      user {
        id
        firstName
      }
      asks {
        id
        text
        order
        createdAt
      }
    }
  }
`

export const UPDATE_PROJECT_SOUNDTRACK_MUTATION = gql`
  mutation ($soundtrackUrl: String, $slug: String!, $token: String!) {
    updateSoundTrack(soundtrackUrl: $soundtrackUrl, slug: $slug, token: $token) {
      soundtrackUrl
    }
  }
`

export const SEND_PERSONAL_INVITE_MUTATION = gql`
  mutation ($projectId: ID!, $participantSlug: String, $note: String) {
    sendPersonalInvite(projectId: $projectId, participantSlug: $participantSlug, note: $note) {
      success
      errors {
        path
        detail
      }
    }
  }
`

export const SEND_INVITE_MUTATION = gql`
  mutation ($participantIds: [ID!]!) {
    sendInvite(participantIds: $participantIds) {
      success
      errors {
        path
        detail
      }
    }
  }
`

export const UPDATE_INTRO_VIDEO_FROM_TWILIO_MUTATION = gql`
  mutation ($projectId: ID!, $roomSid: String!) {
    updateIntroVideoFromTwilio(projectId: $projectId, roomSid: $roomSid) {
      id
      userId
      title
      deadline
      recipientName
      recipientEmail
      introVideoUrl
    }
  }
`

export const ADD_INTRO_VIDEO_MUTATION = gql`
  mutation ($input: UploadVideoInput!) {
    uploadVideo(input: $input) {
      videoUrl
    }
  }
`

export const ADD_VIDEO_MUTATION = gql`
  mutation ($input: AddVideoInput!) {
    addVideo(input: $input) {
      id
      videoUrl
    }
  }
`

export const ADD_VIDEO_FROM_TWILIO_MUTATION = gql`
  mutation ($askId: ID!, $roomSid: String!) {
    addVideoFromTwilio (askId: $askId, roomSid: $roomSid) {
      id
      videoUrl
    }
  }
`

export const ADD_PARTICIPANT_VIDEO_MUTATION = gql`
  mutation ($slug: String!, $askId: ID!, $file: File!) {
    addParticipantVideo (slug: $slug, askId: $askId, file: $file) {
      id
      videoUrl
    }
  }
`

export const ADD_PARTICIPANT_VIDEO_FROM_TWILIO_MUTATION = gql`
  mutation ($slug: String!, $askId: ID!, $roomSid: String!) {
    addParticipantVideoFromTwilio (slug: $slug, askId: $askId, roomSid: $roomSid) {
      videoUrl
    }
  }
`

export const REMOVE_PARTICIPANT_VIDEO_MUTATION = gql`
  mutation ($slug: String!, $videoId: ID!) {
    removeParticipantVideo(slug: $slug, videoId: $videoId) {
      success
    }
  }
`

export const REMOVE_VIDEO_MUTATION = gql`
  mutation ($videoId: ID!) {
    removeVideo(videoId: $videoId) {
      success
    }
  }
`
export const TRANSCODE_VIDEO_MUTATION = gql`
  mutation ($videoId: ID!) {
    transcodeVideo(videoId: $videoId) {
      success
    }
  }
`

export const CREATE_ASK_MUTATION = gql`
  mutation ($input: CreateAskInput!) {
    createAsk(input: $input) {
      ask {
        id
        projectId
        text
        order
      }
      errors {
        path
        detail
      }
    }
  }
`

export const UPDATE_ASK_MUTATION = gql`
  mutation ($id: ID!, $text: String!) {
    updateAsk(id: $id, text: $text) {
      ask {
        id
        projectId
        text
        order
      }
      errors {
        path
        detail
      }
    }
  }
`

export const DELETE_ASK_MUTATION = gql`
  mutation ($askId: ID!) {
    deleteAsk(askId: $askId) {
      success
      errors {
        path
        detail
      }
    }
  }
`

export const INVITE_PEOPLE_MUTATION = gql`
  mutation ($projectId: ID!, $participants: [AddParticipantInput!]!) {
    addParticipants(projectId: $projectId, participants: $participants) {
      participants {
        id
        name
        email
        uploaded
      }
      errors {
        path
        detail
      }
    }
  }
`

export const INVITE_PEOPLE_FROM_SLUG_MUTATION = gql`
  mutation ($projectSlug: String!, $participants: [AddParticipantInput!]!) {
    addParticipantsFromSlug(projectSlug: $projectSlug, participants: $participants) {
      participants {
        slug
        name
        email
        uploaded
        project {
          id
          title
          slug
          recipientName
          introVideoUrl
          canInvite
          shareUrl
          user {
            firstName
          }
          asks {
            id
            text
            order
            createdAt
          }
        }
      }
      errors {
        path
        detail
      }
    }
  }
`

export const INVITE_PEOPLE_WITH_PARTICIPANT_SLUG_MUTATION = gql`
  mutation ($participantSlug: String!, $participants: [AddParticipantInput!]!) {
    addParticipantsWithParticipantSlug(participantSlug: $participantSlug, participants: $participants) {
      participants {
        slug
        name
        email
        uploaded
      }
      errors {
        path
        detail
      }
    }
  }
`

export const REMOVE_PERSON_MUTATION = gql`
  mutation ($projectId: ID!, $participantEmail: String!) {
    removeParticipant(projectId: $projectId, participantEmail: $participantEmail) {
      participant {
        name
        email
        uploaded
      }
      success
      errors {
        path
        detail
      }
    }
  }
`

export const UPLOAD_VIDEO_MUTATION = gql`
  mutation ($input: UploadVideoInput!) {
    uploadVideo(input: $input) {
      videoUrl
    }
  }
`

export const JOIN_SLACK_CHANNEL_MUTATION = gql`
  mutation ($authenticationToken: String!, $channelId: String!) {
    slackJoinChannel(authenticationToken: $authenticationToken, channelId: $channelId) {
      channel {
        id
        name
      }
      success
      errors {
        path
        detail
      }
    }
  }
`

export const SLACK_USER_UPLOAD_INTRO_VIDEO_MUTATION = gql`
  mutation ($authenticationToken: String!, $file: File!) {
    slackUserUploadIntroVideo (authenticationToken: $authenticationToken, file: $file) {
      success
      introVideoUrl
      errors {
        path
        detail
      }
    }
  }
`

export const UNSUBSCRIBE_EMAIL_MUTATION = gql`
  mutation ($email: String!, $groupIds: [ID!]!) {
    unsubscribeEmail(email: $email, groupIds: $groupIds) {
      success
      errors {
        path
        detail
      }
    }
  }
`

export const UPDATE_BIRTHDAY_CONTACT_MUTATION = gql`
  mutation ($input: UpdateContactInput!) {
    updateContact(input: $input) {
      contact {
        id
        firstName
        lastName
        email
        birthdate
        reminderActive
      }
      errors {
        path
        detail
      }
    }
  }
`

export const UPDATE_CONTACT_BIRTHDATE_WITH_SLUG_MUTATION = gql`
  mutation ($input: UpdateContactBirthdateInput!) {
    updateContactBirthdate(input: $input) {
      success
      errors {
        path
        detail
      }
    }
  }
`